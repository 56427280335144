import { Environment } from './interface';

const theme = `
:root {
    /* Palette */
    --neutral-100: hsl(0, 0%, 93%);
    --neutral-900: rgb(52 52 52);
    --primary-10: rgb(255,255,255);
    --primary-20: rgb(255, 0, 0); // bilet
    --primary-30: rgb(255 15 0);
    --primary-50: rgb(255, 0, 0); // tło
    --primary-100: rgb(94 128 165);

    /* General */
    --base-bg-color: var(--neutral-100);
    --base-font-color: var(--neutral-900);
    --base-font-family: 'Open Sans Condensed', sans-serif;

    /* Utils */
    --active-font-color: var(--primary-100);
    --text-active: var(--primary-100);

    /* Admin panel */
    --admin-panel-bg: var(--primary-10);

    /* Navigation */
    --navigation-desktop-grid: 50px 1fr auto !important;

    /* Ticket */
    --ticket-bg: var(--primary-20);
    --ticket-text-color: var(--neutral-900);
    --ticket-bg-hover: var(--primary-50);
    --ticket-text-color-hover: var(--neutral-100);

    /* Product */
    --product-bg: #004982;
    --product-text-color: #fff;
    --product-bg-hover: #8e8e8e;

    /* Sections */
    --section-header-font-size: 2.5rem;
    --section-header-line-height: 2;
    --section-header-text-align: center;

    /* Search ticket */
    --search-ticket-bg: var(--primary-50);
    --search-ticket-text-color: var(--neutral-100);
    --search-ticket-text-color: var(--neutral-100);
    --search-ticket-font-size: 1.25rem;
    --search-ticket-text-font-weight: 700;

    /* Shadows */
    --shadow-box-shadow: none;
    --shadow-border: 2px solid #f4f4f4;

    /* Cards */
    --card-mobile-padding: 1.25rem;
    --card-desktop-padding: 1.25rem 1rem;
    --card-header-padding: 0 0 1.25rem 0;
    --card-header-text-align: left;
    --card-header-font-weight: 700;
    --card-border-radius: 5px;
    --card-content-border-left: 2px solid var(--primary-50);
    --card-content-padding: 0 0 0 0.75rem;
    --card-even-bg: transparent;
    --card-even-header-text-color: var(--primary-50);
    --card-even-content-text-color: var(--neutral-900);
    --card-odd-bg: transparent;
    --card-odd-header-text-color: var(--primary-50);
    --card-odd-content-text-color: var(--neutral-900);

    /* Credit card */
    --credit-card-bg: var(--primary-10);

    /* Button bg */
    --button-bg: var(--primary-30);
    --button-bg-hover: var(--primary-100);
    --button-text-color: var(--neutral-900);
    --button-text-color-hover: var(--primary-30);
}
`;

export const environment: Environment = {
    defaultGuestPassword: `$YLX7-jvyUyQgD1`,
    production: true,
    wkfUrl: `https://google.pl`,
    apiUrl: `https://gpa-test.mera-systemy.app/api`,
    tags: [
        {
            name: `single-journey`,
            value: `single-journey`,
        },
        {
            name: `short-term`,
            value: `short-term`,
        },
        {
            name: `periodic`,
            value: `periodic`,
        },
        {
            name: `normal`,
            value: `normal`,
        },
        {
            name: `reduced`,
            value: `reduced`,
        },
    ],
    // Look customization.
    instanceConfiguration: {
        logo: `/assets/local-wkd/logo.png`,
        headHtml: `
<link rel="icon" type="image/x-icon" href="./assets/local/favicon.ico" />
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap" rel="stylesheet">
        `,
        theme,
        global: {
            appTitle: `GPA sklep`,
            captchaKey: `6LeDQTEaAAAAAECCOAOCNjh2GpMfUzi79OoRG4sh`,
        },
        payments: {
            maxTransactionAwaitTime: 1200,
        },
        contact: {
            companyName: `Mera`,
            companyText: `
            <br>
            05-825 Grodzisk <br>
            Maz. ul. Mariana Langiewicza 16<br>
            BDO: 000090819<br>
            <br><br>
            Tel.: +48 22 437 92 85 reklamacje pasażerskie <br>
            Reklamacje: reklamacje@mera-systemy.pl <br>
            Wystawienie FV za bilety: faktury@mera-serwis.pl <br>
            <br><br>
            Biuro Obsługi Klienta czynne:<br>
            Poniedziałek – Piątek, 8.00 – 19.00
            `,
            mapUrl: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1058.723655494726!2d20.624746998435306!3d52.108831627806126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471949ed9bf615bb%3A0x267124510ea1bbca!2sZwi%C4%85zek%20Powiatowo-%20Gminny%20%22Grodziskie%20Przewozy%20Autobusowe%22%20-%20GPA!5e0!3m2!1spl!2spl!4v1702983617530!5m2!1spl!2spl`,
        },
        home: {
            mainText: `home.title`,
            textClass: `text-3xl md:text-4xl text-center md:text-left self-center py-10`,
            cards: [
                // `<img class="rounded-full mx-auto pb-6" src="/assets/icons/houses.svg" width="128" height="128" />`,
                // `<img class="rounded-full mx-auto pb-6" src="/assets/icons/houses.svg" width="128" height="128" />`,
                // `<img class="rounded-full mx-auto pb-6" src="/assets/icons/houses.svg" width="128" height="128" />`,
            ],
            sliderTickets: [
                `Normalny 20 minutowy`,
                `Normalny 75 minutowy`,
                `Normalny 90 minutowy`,
                `Ulgowy 20 minutowy`,
                `Ulgowy 75 minutowy`,
                `Ulgowy 90 minutowy`,
                `Normalny na okaziciela 30-dniowy (strefa 1+2)`,
                `Ulgowy na okaziciela 30-dniowy ulgowy (strefa 1+2)`,
                `Ulgowy dobowy na strefę 1 (24h)`,
            ],
        },
    },
};

// cards: [
//     `<div class="rounded-full mx-auto h-32 w-32 bg-white text-5xl primary-font-color flex justify-center items-center font-bold">1</div>`,
//     `<div class="rounded-full mx-auto h-32 w-32 bg-white text-5xl primary-font-color flex justify-center items-center font-bold">2</div>`,
//     `<div class="rounded-full mx-auto h-32 w-32 bg-white text-5xl primary-font-color flex justify-center items-center font-bold">3</div>`,
// ],
